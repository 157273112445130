<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card>
            <v-toolbar flat class="subordinate--title">
              <h3 class="title text--darken-2 font-weight-regular">
                {{ $t("card.subordinate-mapping") }}
              </h3>
              <h6 class="subtitle-2 font-weight-light">
                {{ $t("label.specify-the-employees-upline-downline") }}
              </h6>
              <v-spacer></v-spacer>
              <div v-if="userIsSuperAdmin">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="mx-2"
                      small
                      fab
                      dark
                      color="error"
                      @click="syncWithSimkar"
                    >
                      <v-icon dark>
                        {{ iconSync }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("button.sync-with-simkar") }}</span>
                </v-tooltip>
              </div>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-5">
              <v-row class="mt-4" v-if="userIsSuperAdmin">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedUnit"
                    v-bind:items="listUnit"
                    v-bind:filter="unitFilter"
                    ref="selectunit"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="kodeunitbagian"
                    clearable
                    label="Unit"
                    placeholder="Unit"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listBagian.length > 0 && userIsSuperAdmin">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedBagian"
                    v-bind:items="listBagian"
                    v-bind:filter="bagianFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="kodebagian"
                    clearable
                    v-bind:label="labelBagian"
                    v-bind:placeholder="labelBagian"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listType.length > 0">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedType"
                    v-bind:items="listType"
                    v-bind:filter="typeFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="type"
                    clearable
                    v-bind:label="labelType"
                    v-bind:placeholder="labelType"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listKaryawan.length > 0">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedKaryawan"
                    v-bind:items="listKaryawan"
                    v-bind:filter="karyawanFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="nama"
                    item-value="nik"
                    clearable
                    v-bind:label="labelKaryawan"
                    v-bind:placeholder="labelKaryawan"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.nama }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selectedKaryawan" class="mt-4">
                <v-col cols="12" md="6" sm="12" class="mx-auto my-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.selected-upline")
                    }}</v-subheader>

                    <v-list-item class="cursor--default">
                      <v-avatar class="mr-2">
                        <v-avatar
                          class="mr-2"
                          v-if="getSelectedKaryawan.avatar_path"
                        >
                          <img :src="getSelectedKaryawan.avatar_path" />
                        </v-avatar>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          getSelectedKaryawan.nama
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          getSelectedKaryawan.jabatan
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row v-if="getSelectedKaryawan">
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.available-downline")
                    }}</v-subheader>
                    <div
                      class="table-responsive-container scroll__y-only"
                      style="max-height: 400px"
                    >
                      <div class="table-responsive">
                        <draggable
                          v-model="availableDownline"
                          group="people"
                          style="min-height: 100px; margin-bottom: 1rem"
                        >
                          <template v-for="item in availableDownline">
                            <v-list-item :key="item.id" class="cursor--grab">
                              <v-avatar class="mr-2">
                                <img :src="item.avatar_path" />
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.nama"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-html="item.jabatan"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </draggable>
                      </div>
                    </div>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.selected-downline")
                    }}</v-subheader>
                    <div
                      class="table-responsive-container scroll__y-only"
                      style="max-height: 400px"
                    >
                      <div class="table-responsive">
                        <draggable
                          v-model="selectedDownline"
                          group="people"
                          style="min-height: 100px; margin-bottom: 1rem"
                        >
                          <template v-for="(item,idx) in selectedDownline">
                            <v-list-item :key="idx" class="cursor--grab">
                              <v-avatar class="mr-2">
                                <img :src="item.avatar_path" />
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.nama"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-html="item.jabatan"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </draggable>
                      </div>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-1 d-flex justify-end">
                  <v-btn
                    :disabled="
                      !selectedKaryawan &&
                      (!availableDownline || availableDownline.length < 1)
                    "
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="saveChange"
                  >
                    <v-icon>{{ iconSave }}</v-icon>
                    {{ $t("button.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
    <v-overlay :value="axiosLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p class="mt-3">{{ $t("loader.please-wait") }}</p>
    </v-overlay>
  </v-container>
</template>
<script>
import { mdiContentSaveOutline, mdiDatabaseSync, mdiHelpCircle } from "@mdi/js";
// import Avatar from "vue-avatar-component";
// import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
export default {
  name: "user-mapping",
  components: {
    draggable,
    // Avatar,
    // Sortable
  },
  data() {
    return {
      iconSave: mdiContentSaveOutline,
      iconSync: mdiDatabaseSync,
      iconQuestion: mdiHelpCircle,
      selectedUnit: undefined,
      selectedBagian: "",
      selectedType: undefined,
      selectedKaryawan: undefined,
      selectedUpline: undefined,
      selectedDownline: undefined,
      availableDownline: [],
      labelBagian: this.$t("label.department"),
      labelKaryawan: this.$t("label.employee"),
      labelType: this.$t("label.elemen-user-types"),
    };
  },
  created() {
    this.resetAll();
    if (this.userIsSuperAdmin) {
      return this.loadUnits();
    }
    this.selectedUnit = this.currentUser.kode_unit;
    // this.loadKaryawan(this.currentUser.kode_unit, this.currentUser.kode_unit.kode_bagian);
  },
  watch: {
    selectedUnit(val) {
      let unitKanpus = ['00','10'];
      if (!val) return;
      this.resetAll();
      this.selectedBagian = "";
      if (this.userIsSuperAdmin) {
        if (unitKanpus.includes(val)) {
          this.getBagians({ kodeUnit: val });
        } else {
          this.getTypes({
            kodeUnit: val,
            kodeBagian: "",
            without: "KRANI,SEKRETARIS,SEKRETARIS_KRANI",
          });
        }
      } else {
        if (val == "00") {
        this.selectedBagian = this.currentUser.kode_bagian;
        }
        else {
           this.selectedBagian = "";
           this.getTypes({
            kodeUnit: val,
            kodeBagian: "",
            without: "KRANI,SEKRETARIS,SEKRETARIS_KRANI",
          });
        }
      }
    },
    selectedBagian(val) {
      this.selectedType = undefined;
      this.selectedKaryawan = undefined;
      this.selectedDownline = undefined;
      // this.availableDownline = [];
      this.setTypes(undefined);
      this.setKaryawans(undefined);
      this.setBawahanTersedia(undefined);
      this.setBawahanTerpilih(undefined);
      if (!val) return;
      if (val =="ALL") {
        this.getTypes({
          kodeUnit: this.selectedUnit,
          kodeBagian: "",
          without: "KRANI,SEKRETARIS",
        });
      }
      else {
          this.getTypes({
          kodeUnit: this.selectedUnit,
          kodeBagian: val,
          without: "KRANI,SEKRETARIS",
        });
      }
    },
    selectedType(val) {
      // this.availableDownline = [];
      this.selectedKaryawan = undefined;
      this.setKaryawans(undefined);
      this.setBawahanTersedia(undefined);
      this.setBawahanTerpilih(undefined);
      this.selectedDownline = undefined;
      if (!val) return;
      this.getKaryawans({
        kodeUnit: this.selectedUnit,
        kodeBagian: this.selectedBagian,
        type: val,
      });
    },
    selectedKaryawan(val) {
      this.selectedDownline = undefined;
      // this.availableDownline = [];
      this.setBawahanTersedia(undefined);
      this.setBawahanTerpilih(undefined);
      if (!val) return;
      this.getBawahanTerpilih({
        nikAtasan: val,
      });
      this.getBawahanTersedia({
          kodeUnit: this.selectedUnit,
          kodeBagian: this.selectedBagian,
          nikAtasan: this.selectedKaryawan,
          nikBawahan: null,
          userTypeElemen: this.selectedType,
      });

    },
    bawahanTerpilih(val) {
    //   // this.availableDownline = [];
      if (!this.selectedKaryawan) return;
      this.selectedDownline = val;
    //   setTimeout(() => {
    //     this.getBawahanTersedia({
    //       kodeUnit: this.selectedUnit,
    //       kodeBagian: this.selectedBagian,
    //       nikAtasan: this.selectedKaryawan,
    //       nikBawahan: this.mapNikBawahan(val),
    //       userTypeElemen: this.selectedType,
    //     });
    //   }, 200);
    },
    bawahanTersedia(val, oldVal) {
      if (val === oldVal) return;
      if (!val || val.length < 1) return (this.availableDownline = []);
      this.availableDownline = val;
    },
  },
  computed: {
    ...mapState("resources", [
      "units",
      "bagians",
      "karyawans",
      "types",
      "axiosLoading",
      "bawahanTersedia",
      "bawahanTerpilih",
    ]),
    ...mapState("user", ["currentUser"]),
    userIsSuperAdmin() {
      if (!this.currentUser) return false;
      return this.currentUser.superadmin === true;
    },
    listUnit() {
      if (!this.units) return [];
      return this.units;
    },
    listBagian() {
      if (!this.bagians) return [];
      return this.bagians;
    },
    listType() {
      if (!this.types) return [];
      return this.types;
    },
    listKaryawan() {
      if (!this.karyawans) return [];
      return this.karyawans;
    },
    getSelectedKaryawan() {
      if (!this.karyawans || !this.selectedKaryawan) return undefined;
      let result = this.karyawans.filter(
        (item) => item.nik == this.selectedKaryawan
      );
      return result[0];
    },
    selectedUplineType() {
      if (!this.selectedType) return undefined;
      let result = this.types.filter((item) => item.type == this.selectedType);
      if (result.length > 0) return parseInt(result[0].level);
      return undefined;
    },
    // dataIsChanged(){
    //   if (!this.bawahanTersedia && !this.bawahanTerpilih) return false;
    //   if (!this.availableDownline && !this.selectedDownline) return false;
    //   if ((this.bawahanTersedia && this.bawahanTersedia.length === this.availableDownline.length) && (this.bawahanTerpilih && this.bawahanTerpilih.length === this.selectedDownline.length))  return false;
    //   return true;
    // }
    // selectedDownline: {
    //   get() {
    //     return this.bawahanTerpilih;
    //   },
    //   set(val) {
    //     this.$store.commit("resources/SET_BAWAHAN_TERPILIH", val);
    //   },
    // },
    // availableDownline: {
    //   get() {
    //     return this.bawahanTersedia;
    //   },
    //   set(val) {
    //     this.$store.commit("resources/SET_BAWAHAN_TERSEDIA", val);
    //   },
    // },
  },
  methods: {
    ...mapActions("resources", [
      "getUnits",
      "getBagians",
      "getKaryawans",
      "getTypes",
      "setUnits",
      "setBagians",
      "setTypes",
      "setJabatans",
      "setKaryawans",
      "setRoles",
      "getBawahanTersedia",
      "getBawahanTerpilih",
      "setBawahanTersedia",
      "setBawahanTerpilih",
      "updateBawahan",
    ]),
    saveChange() {
      if (!this.selectedDownline || !this.availableDownline) return;
      let arrNik = this.mapNikBawahan(this.selectedDownline);

      let formData = {
        nik: arrNik,
        nik_atasan: this.selectedKaryawan,
        kode_unit: this.selectedUnit,
        kode_bagian: this.selectedBagian,
      };
      this.updateBawahan({ formData: formData });
    },
    mapNikBawahan(val) {
      if (!val) return undefined;
      let arr = val.map(({ nik }) => nik);
      return arr.join();
    },
    resetAll() {
      this.selectedDownline = undefined;
      this.availableDownline = [];
      this.selectedBagian = "";
      this.selectedType = undefined;
      this.selectedKaryawan = undefined;
      this.setBagians(undefined);
      this.setTypes(undefined);
      this.setKaryawans(undefined);
      this.setBawahanTersedia(undefined);
      this.setBawahanTerpilih(undefined);
    },
    loadUnits() {
      this.getUnits();
    },
    unitFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.kodeunitbagian.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    bagianFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.kodebagian.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    typeFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.type.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    karyawanFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.nama.toLowerCase();
      const textTwo = item.jabatan.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    syncWithSimkar(){
      this.$refs.selectunit.focus();
       this.$dialog
        .confirm({
          text: this.$t("msgbox.data-synchronization-will-cause-the-user-to-lose-access-are-you-sure"),
          title: this.$t("msgbox.sync-to-simkar"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-proceed"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;
          //doAxios
        });
    }
  },
};
</script>
<style>
.subordinate--title .v-toolbar__content {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-between;
}
</style>